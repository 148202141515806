
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import { AreaList } from '@/types/irrigation'
import { PolygonStyle } from '@/types/common'

@Component
export default class IrrigationArea extends Vue {
  areaList: AreaList[] = []
  currentIndex = 0
  private map: AMap.Map | any = null
  private ploygons: Array<AMap.Polygon> = []

  private polygonCurrentStyle: PolygonStyle = {
    strokeColor: '#3592FE',
    fillColor: '#3592FE',
    strokeWeight: 4,
    strokeOpacity: 1,
    fillOpacity: 0.3,
    strokeStyle: 'solid',
    zIndex: 100
  }

  tableData = []

  get projectId () {
    return this.$route.query.projectId
  }

  created () {
    !this.map && this.loadMap().then(() => {
      this.getAreaList()
    })
  }

  destroyed () {
    if (this.map) {
      this.map.destroy()
    }
  }

  // 获取区域
  getAreaList () {
    this.$axios.get(this.$apis.project.selectYhProjectAreaByList, {
      projectId: this.projectId
    }).then(res => {
      this.areaList = res || []
      this.areaList.length > 0 && this.selectArea(0)
    })
  }

  // 选中区域
  selectArea (index: number) {
    this.currentIndex = index
    this.map && this.map.remove(this.ploygons)
    this.getDetail()
  }

  // 区域详情
  getDetail () {
    this.$axios.get(this.$apis.project.selectYhProjectAreaByProjectAreaId, {
      projectAreaId: this.areaList[this.currentIndex].projectAreaId
    }).then((res) => {
      if (this.map) {
        // 区域
        this.ploygons = drawPolygon(this.map, res.locationList, this.polygonCurrentStyle, null)
        this.map.setZoom(14)
      }
    })
  }

  // 地图初始化
  loadMap () {
    return AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const map = new AMap.Map('mapContainer', {
        zoom: 11
      })
      this.map = map
    })
  }

  addArea () {
    this.$router.push({
      path: '/projectList/detail/subzone/add',
      query: {
        projectId: this.projectId
      }
    })
  }

  updateArea (areaId: string) {
    this.$router.push({
      path: '/projectList/detail/subzone/add',
      query: {
        projectId: this.projectId,
        areaId: areaId
      }
    })
  }

  deleteArea (areaId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.project.deleteYhProjectArea, {
        projectAreaId: areaId
      }).then(() => {
        this.currentIndex = 0
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.map && this.map.remove(this.ploygons)
        this.getAreaList()
      })
    })
  }
}
